@use '../../shared.scss' as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.disclaimerNA {
  margin-top: 32px;
}

.simpleSegmentRoot {
  border-bottom: 1px solid
    var(--light-neutral-color-contrast-medium-light, #6b6d70);
  padding: 8px 0 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }
}

.removeDisclaimer {
  > div:last-child {
    border-bottom: none !important;
  }
}
