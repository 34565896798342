@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.tariffTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0;
  margin-bottom: 0;
}

.priceContainer {
  background-color: $pds-theme-light-background-surface;
  margin-top: $pds-spacing-fluid-small;
  padding: $pds-spacing-fluid-small;
  border-radius: $pds-border-radius-large;
  > :last-child {
    border-bottom: none;
  }
}

.priceLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: $pds-spacing-static-small;
  align-items: center;
}

.subTitle {
  padding-top: $pds-spacing-fluid-large;
}

.plantList {
  padding-top: $pds-spacing-fluid-large;
  display: flex;
  gap: $pds-spacing-fluid-small;
  flex-wrap: wrap;
}

.planCardLabel {
  cursor: pointer;
  display: flex;
  gap: $pds-spacing-static-medium;
}

.simpleSegmentRoot {
  border-bottom: 1px solid $pds-theme-light-contrast-low;
  padding: 8px 0 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.plansList {
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: $breakpoint-s-760) {
    flex-direction: column;

    .cardContainer {
      &:first-child {
        margin: 0 0 $pds-spacing-static-small 0;
      }
    }
  }
}

.cardContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: $pds-spacing-static-medium;
  border: 1px solid $pds-theme-light-contrast-medium;
  border-radius: 8px;
  padding: $pds-spacing-static-small;
  flex: 1;

  &:first-child {
    margin-right: $pds-spacing-static-small;
  }
}

.cardContainer.active {
  background-color: $pds-theme-light-background-surface;
}

.pricesWrapper {
  margin-top: $pds-spacing-fluid-small;
  padding: $pds-spacing-fluid-small;
  border-radius: $pds-border-radius-large;
  > :last-child {
    border-bottom: none;
  }
}
