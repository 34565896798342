@use '../suspendedFlyout.module' as flyout;

.root {
  padding-bottom: calc(#{flyout.$spacing} / 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(#{flyout.$spacing} / 2);
}

.column {
  display: flex;
  flex-direction: column;
  gap: calc(#{flyout.$spacing} / 2);
}
