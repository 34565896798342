@use 'src/components/shared' as *;

.root {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-xs-480) {
    width: 100%;
  }

  @media (min-width: $breakpoint-xs-480) {
    width: fit-content;
  }
}
