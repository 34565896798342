@use '@porsche-design-system/components-js/styles' as *;

.root,
.rootWithSpacing {
  display: flex;
  flex-direction: column;
}

.rootWithSpacing {
  margin-top: $pds-spacing-static-medium;
}

.narStarContainer {
  position: relative;
}

.narStart {
  position: absolute;
  top: 0;
  right: -6px;
}
