@use '@porsche-design-system/components-js/styles' as *;
@use '../shared.scss' as *;

.wrapper {
  padding: $pds-spacing-static-medium;
  background-color: $pds-theme-light-notification-warning-soft;
  display: flex;
  flex-wrap: nowrap;
  border-radius: $pds-border-radius-small;

  @media (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }
}

.iconWrapper {
  display: flex;
  align-items: flex-start;
  margin-right: $pds-spacing-static-small;

  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  padding-right: $pds-spacing-static-small;

  @media (max-width: $breakpoint-s-760) {
    margin-bottom: $pds-spacing-static-medium;
  }
}

.buttonWrapper {
  margin-left: auto;
  text-wrap: nowrap;

  @media (max-width: $breakpoint-s-760) {
    margin-left: 0;
  }
}
