@use '@porsche-design-system/components-js/styles' as *;

.text {
  // aligning the label padding to the PCheckboxWrapper porsche component
  padding: 2px 0px 0px 8px;
}

.checkboxContentWrapper {
  margin-bottom: $pds-spacing-static-medium;
}

.additionalText {
  margin-bottom: $pds-spacing-static-medium;
}
