@use './src/components/shared' as *;

.title {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (min-width: $breakpoint-s-760) {
    align-items: center;
    flex-direction: row;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: $breakpoint-s-760) {
    align-items: flex-start;
  }
}

.text {
  text-wrap: nowrap;
}

.infoContainer {
  display: flex;
}

.priceChangeButton {
  margin-left: 8px;
  text-wrap: nowrap;
}
