@use '@porsche-design-system/components-js/styles' as *;
@use '../../../components/shared' as *;

.root {
  padding: $pds-spacing-fluid-medium 0;
  max-width: 1760px;
  margin: 0 max(22px, 10.625vw - 12px);

  @media (min-width: $breakpoint-s-760) {
    margin: 0 calc(5vw - 16px);
  }

  @media (min-width: $breakpoint-xxl-1920) {
    margin: 0 auto;
  }
}
