@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../components/shared' as *;

.root {
  margin-top: $pds-spacing-static-large;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $pds-spacing-static-large;
  row-gap: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-s-760) {
    grid-template-columns: repeat(1, 1fr);
  }
}
