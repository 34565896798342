@use '../../../../components/shared' as *;
$padding: 28px;
$halfPadding: calc(#{$padding} / 2);
$percentilePadding: calc(#{$padding} * 0.75);

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.root {
  width: 100%;
}

.table {
  width: 100%;
  border-radius: 12px;
  padding: $padding;
  background: var(--light-background-surface-light, #eeeff2);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  overflow-x: auto;

  .header {
    @extend .space-between;
    padding-bottom: $percentilePadding;
  }

  .body {
    display: grid;
    grid-auto-rows: 1fr;
    width: 100%;

    @media (max-width: $breakpoint-s-760) {
      grid-auto-rows: unset;
      grid-template-rows: 1fr min-content;
    }

    > div {
      @extend .space-between;
      border-bottom: 1px solid rgb(216, 216, 219);
      padding-top: $percentilePadding;
      padding-bottom: $percentilePadding;
      min-height: 48px;
      width: 100%;

      > *:first-child {
        margin-right: 8px;
      }

      @media (max-width: $breakpoint-s-760) {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
