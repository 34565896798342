@use '@porsche-design-system/components-js/styles' as *;
@use '../../../components/shared.scss' as *;

.container {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: $pds-border-radius-medium;
  overflow: hidden;
}
