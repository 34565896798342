@use 'src/components/shared' as *;

.root {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-s-760) {
    flex-direction: row;
  }
}

.primaryButton {
  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    width: 100%;
  }

  @media (min-width: $breakpoint-s-760) {
    width: max-content;
  }
}
