@use '@porsche-design-system/components-js/styles' as *;

.root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: center;
  padding-top: $pds-spacing-static-large;
  padding-bottom: $pds-spacing-static-large;
}

.footer {
  margin-top: auto;
}
