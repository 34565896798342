@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.root {
  background: $pds-theme-light-background-base;
  border-radius: $pds-border-radius-large;
  padding: $pds-spacing-static-large;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: $breakpoint-m-1000) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.header {
  margin-bottom: $pds-spacing-static-large;
}

.priceLine {
  display: grid;
  grid-template-columns: auto max-content;
}

.totalPriceLine {
  @extend .priceLine;

  margin-bottom: $pds-spacing-static-medium;
}

.divider {
  margin: $pds-spacing-static-medium 0;
}

.accordionContainer {
  display: flex;
  justify-content: space-between;
}

.detailsButton {
  height: fit-content;

  @media screen and (min-width: $breakpoint-m-1000) {
    display: none;
  }
}

.containerClosed {
  height: 0;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-m-1000) {
    height: auto;
  }
}

.containerOpened {
  height: auto;
}

.totalPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
