@use '@porsche-design-system/components-js/styles' as *;

.header {
  display: flex;
  justify-content: center;
  padding-top: $pds-spacing-static-large;
  padding-bottom: $pds-spacing-static-large;
  border-bottom: $pds-border-width-thin solid $pds-theme-light-contrast-low;
}

.title {
  margin-bottom: $pds-spacing-static-medium;
  color: inherit;
}

.text {
  margin-bottom: $pds-spacing-static-medium;
  visibility: visible;
}
