@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.mainWrapper {
  width: 100%;
  padding-top: $pds-spacing-static-medium;
}

.notification:not(empty) {
  margin-bottom: $pds-spacing-static-medium;
}

.contentMainBox {
  min-height: 88px;
  width: 100%;
  background-color: $pds-theme-light-background-surface;
  padding: $pds-spacing-static-medium;
  border-radius: $pds-border-radius-large;
  margin-bottom: $pds-spacing-static-medium;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.externalLinkPlaceholder {
  @extend .contentMainBox;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentPreviewWrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.contentPreviewTextWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contentPreviewActionWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.clickableText {
  height: fit-content;
}

.submitButton,
.cancelButton {
  margin-top: $pds-spacing-static-large;
}

.cancelButton {
  margin-left: $pds-spacing-static-medium;
}

.sectionTitle {
  margin-bottom: $pds-spacing-static-small;
}

.tabTitle {
  margin-bottom: $pds-spacing-static-large;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.error {
  margin-top: $pds-spacing-static-large;
}

.inlineNotification {
  display: flex;
  flex-wrap: nowrap;
  padding: $pds-spacing-static-medium;
  margin-top: $pds-spacing-static-large;
  background-color: $pds-theme-light-notification-info-soft;
  border-radius: $pds-border-radius-small;
}

.inlineNotificationTextWrapper {
  margin-left: $pds-spacing-static-small;
}

.inlineNotificationTitle {
  margin-bottom: $pds-spacing-static-small;
}

.deletePaymentDisclaimer {
  margin-top: $pds-spacing-static-small;
}

.bannerContainer {
  width: 100%;
  max-width: 100%;
  margin-bottom: $pds-spacing-static-medium;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
}

.bannerImage {
  width: 100%;
  height: auto;
  display: block;
  filter: brightness(0.65);
}

.bannerContentContainer {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  width: 60%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-m-1000) {
    transform: translateY(0);
    top: unset;
    bottom: 20px;
    width: calc(
      100% - 80px
    ); // -80px to have 40px spacing from each side that matches left: 40px
  }
}

.bannerTextTitle {
  color: white;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: $pds-spacing-static-medium;

  @media screen and (max-width: $breakpoint-m-1000) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: $pds-spacing-static-small;
  }
}

.bannerTextSubtitle {
  color: white;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: $pds-spacing-static-large;

  @media screen and (max-width: $breakpoint-m-1000) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: $pds-spacing-static-medium;
  }
}

.bannerButtonContainer {
  border: 1px solid white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 18px 16px 16px;
  width: fit-content;

  &:hover {
    background-color: $pds-theme-light-state-hover;
    cursor: pointer;
  }

  @media screen and (max-width: $breakpoint-m-1000) {
    transform: translateY(0);
    top: unset;
    bottom: 20px;
    width: 100%;
    padding: 12px 14px 12px 12px;
  }
}

.inactiveDiv {
  pointer-events: none;
}

.tag {
  display: flex;
  flex-direction: row;
  margin-top: $pds-spacing-static-small;
  gap: 16px;
  align-items: flex-start;

  @media (min-width: $breakpoint-l-1300) {
    display: none;
  }
}

.actionTag {
  display: none;
  margin-top: 0;
  margin-right: $pds-spacing-static-medium;
  @media (min-width: $breakpoint-l-1300) {
    display: flex;
  }
}

.notificationWrapper {
  display: flex;
  padding: $pds-spacing-static-medium;
  margin-bottom: $pds-spacing-static-medium;
  background-color: $pds-theme-light-notification-warning-soft;
  border-radius: $pds-border-radius-small;

  @media screen and (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }
}

.notificationContentWrapper {
  display: flex;
  flex-direction: column;
  margin-left: $pds-spacing-static-medium;

  @media screen and (max-width: $breakpoint-s-760) {
    margin: 0 0 $pds-spacing-static-medium 0;
  }
}

.notificationActionWrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  text-wrap: nowrap;

  @media screen and (max-width: $breakpoint-s-760) {
    margin-left: 0;
  }
}

.notificationIcon {
  @media screen and (max-width: $breakpoint-s-760) {
    display: none;
  }
}
