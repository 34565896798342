@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.mainContainer {
  background-color: white;
  border-radius: 4px;
  padding: $pds-spacing-static-medium;

  &:not(:last-child) {
    margin-bottom: $pds-spacing-static-medium;
  }
}

.alwaysVisible {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: flex-start;
}

.alwaysVisible.isOpen {
  margin-bottom: $pds-spacing-static-large;
}

.buttonContainer {
  margin-left: auto;
  white-space: nowrap;
}

.actionButtonContainer {
  margin-top: $pds-spacing-static-large;
  display: flex;

  @media (max-width: $breakpoint-m-1000) {
    flex-direction: column;
  }
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border: 1px solid $pds-theme-dark-background-base;
  border-radius: 100%;
  margin-right: $pds-spacing-static-small;
}

.step.active {
  background-color: black;
  color: white;
}

.title {
  display: flex;
  flex-direction: column;
}

.secondaryButton {
  margin-left: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-m-1000) {
    margin-left: 0;
    margin-top: $pds-spacing-static-small;
  }
}
