$flyoutPadding: clamp(32px, 2.75vw + 23px, 76px);
$spacing: 28px;

.root {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-items: flex-start;
  padding-bottom: calc(#{$flyoutPadding} - 16px);
  gap: 36px;
}

.customerService {
  padding: 36px 0 0 0;
}
