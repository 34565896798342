@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.orderLine {
  display: flex;
  justify-content: space-between;
}

.orderSummaryHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.hiddenContent {
  display: none;
}

.textContainer {
  display: flex;
  color: $pds-theme-light-contrast-medium;
  font-size: 14px;
}

.textSubtitle {
  font-weight: 600;
}

.titleText {
  font-size: 24px;
  font-weight: 600;
}

.additionalDataWrapper {
  display: flex;
  padding-top: 24px;
  justify-content: space-between;
}

.baseFeeWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
