@use './src/components/shared' as *;
@use '@porsche-design-system/components-js/styles' as *;

.center {
  height: 500px;
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.desktopView {
  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.mobileView {
  padding: $pds-spacing-static-large;

  div[class^='inlineTariff_simpleSegmentRoot'] {
    flex-direction: column;
  }

  div[class^='charging_simpleSegmentTextContainer'] {
    align-items: flex-start;
  }

  .buttonContainer {
    display: contents;
    width: 100%;
  }

  @media (min-width: $breakpoint-s-760) {
    display: none;
  }
}
