@use 'src/pages/management/components/subscriptionManagement/subscriptionManagement.module'
  as *;
@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../components/shared' as *;

.root,
.rootDisabled {
  margin-bottom: $pds-spacing-static-medium;
  display: flex;
}

.rootDisabled {
  pointer-events: none;
}

.tile {
  @extend .contentMainBox;
  cursor: pointer;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (min-width: $breakpoint-s-760) {
    flex-wrap: nowrap;
    row-gap: initial;
  }
}

.columnContainer {
  display: flex;
  flex-direction: column;

  > .tag {
    justify-content: flex-start;
    margin: $pds-spacing-static-medium 0 0 0;
  }

  @media (min-width: $breakpoint-m-1000) {
    > .tag {
      display: none;
    }
  }
}

.tag {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 36px;
  margin-top: 0;
  margin-left: $pds-spacing-static-small;
}

.subtitleContainer {
  display: flex;
}

.subtext {
  margin-top: $pds-spacing-static-medium;
}

.titleWithTagContainer {
  display: flex;
  margin-bottom: $pds-spacing-static-medium;
  align-items: center;

  @media (max-width: $breakpoint-m-1000) {
    flex-direction: column;
    align-items: flex-start;

    > .tag {
      display: none;
    }
  }
}

.buttonContainer {
  display: flex;
  height: fit-content;
  width: fit-content;
}
