@use '../shared' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 80px;

  // Make sure that the breakpoints behave like the layout the main content is in!

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-xs-480) {
    margin: 0 30px 0 30px;
  }

  @media (min-width: $breakpoint-xs-480) {
    margin: 0 82px 0 82px;
  }
}
