@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.skeletonHeader {
  display: grid;
  padding-top: $pds-spacing-fluid-medium;
  padding-bottom: $pds-spacing-fluid-medium;
  gap: $pds-spacing-fluid-small;
  @media (min-width: $breakpoint-s-760) {
    max-width: 40%;
  }
  > :first-child {
    border-radius: $pds-border-radius-small;
    min-height: 3rem;
    max-width: 80%;
  }
  > :last-child {
    border-radius: $pds-border-radius-small;
    max-width: 70%;
  }
}
.plansSkeleton {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $breakpoint-s-760) {
    flex-wrap: nowrap;
  }
  gap: $pds-spacing-fluid-medium;
  padding-top: $pds-spacing-fluid-medium;
  > div {
    &::before {
      content: '';
      @include pskeleton();
      position: absolute;
      top: 0;
      width: 2rem;
      height: 2rem;
      left: -3rem;
      border-radius: 50%;
    }
    margin-left: 3rem;
    position: relative;
    width: 100%;
    display: grid;
    gap: $pds-spacing-fluid-small;
    > div {
      border-radius: $pds-border-radius-small;
    }
    > :first-child {
      min-height: 3rem;
      max-width: 80%;
    }
    > :nth-child(2) {
      max-width: 90%;
    }
    > :last-child {
      height: 14rem;
      border-radius: $pds-border-radius-large;
    }
  }
}
