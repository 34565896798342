@use '@porsche-design-system/components-js/styles' as *;

$padding: 28px;
$halfPadding: calc(#{$padding} / 2);
$percentilePadding: calc(#{$padding} * 0.75);

.table {
  width: 100%;
  border-radius: 12px;
  padding: $padding;
  background: var(--light-background-surface-light, #eeeff2);
  gap: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  overflow-x: auto;
  margin-bottom: $pds-spacing-static-medium;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 13px;
}

.error {
  margin-bottom: 36px;
}

.radioGroup {
  :not(:last-child) {
    margin-bottom: $pds-spacing-static-medium;
  }
}
