@use '@porsche-design-system/components-js/styles' as *;

.root {
  background: $pds-theme-light-background-base;
  border-radius: $pds-border-radius-large;
  padding: $pds-spacing-static-large;
}

.header {
  display: grid;
  gap: $pds-spacing-static-medium;
  grid-template-columns: min-content auto max-content;
  align-items: center;
}

.stepIndicator {
  background-color: $pds-theme-light-primary;
  color: $pds-theme-light-background-base;
  border: 1px solid $pds-theme-light-background-base;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22.4px;
  width: 22.4px;

  @include pds-text-xx-small;
  line-height: 1;
}

.stepIndicatorActive {
  @extend .stepIndicator;

  background-color: $pds-theme-light-primary;
  color: $pds-theme-light-background-base;
  border-color: $pds-theme-light-background-base;
}

.stepIndicatorComplete {
  @extend .stepIndicator;

  background-color: $pds-theme-light-primary;
  color: $pds-theme-light-primary;
  border-color: $pds-theme-light-primary;
}

.stepIndicatorInactive {
  @extend .stepIndicator;

  background-color: $pds-theme-light-background-base;
  color: $pds-theme-light-state-disabled;
  border-color: $pds-theme-light-state-disabled;
}

.error {
  margin-top: $pds-spacing-static-large;
}

.submitButton {
  margin-top: $pds-spacing-static-large;
}
