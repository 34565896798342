@use '../../tariffOverview.module' as overview;
@use './src/components/shared' as *;

.text {
  padding-top: overview.$padding;

  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    gap: 8px;
  }
}
