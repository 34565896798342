@use '../../../../node_modules/@porsche-design-system/components-js/styles/index'
  as *;

$breakpoint-base-0: $pds-breakpoint-base * 1px;
$breakpoint-xs-480: $pds-breakpoint-xs * 1px;
$breakpoint-s-760: $pds-breakpoint-s * 1px;
$breakpoint-m-1000: $pds-breakpoint-m * 1px;
$breakpoint-l-1300: $pds-breakpoint-l * 1px;
$breakpoint-xl-1760: $pds-breakpoint-xl * 1px;
$breakpoint-xxl-1920: $pds-breakpoint-xxl * 1px;

$pds-spacing: (
  1: $pds-spacing-fluid-x-small,
  2: $pds-spacing-fluid-small,
  3: $pds-spacing-fluid-medium,
  4: $pds-spacing-fluid-large,
  5: $pds-spacing-fluid-x-large,
  6: $pds-spacing-fluid-xx-large,
);

@mixin padding-setter($attribute) {
  @each $name, $size in $pds-spacing {
    &#{$name} {
      #{$attribute}: $size;
    }
  }
}
.pt {
  @include padding-setter(padding-top);
}
.pb {
  @include padding-setter(padding-bottom);
}
.mt {
  @include padding-setter(margin-top);
}
.mb {
  @include padding-setter(margin-bottom);
}
.pl {
  @include padding-setter(padding-left);
}
.pr {
  @include padding-setter(padding-right);
}
.ml {
  @include padding-setter(margin-left);
}
.mr {
  @include padding-setter(margin-right);
}
.px {
  @include padding-setter(padding-left);
}
.px {
  @include padding-setter(padding-right);
}
.mx {
  @include padding-setter(margin-left);
}
.mx {
  @include padding-setter(margin-right);
}
.py {
  @include padding-setter(padding-top);
}
.py {
  @include padding-setter(padding-bottom);
}
.my {
  @include padding-setter(margin-top);
}
.my {
  @include padding-setter(margin-bottom);
}

@mixin pskeleton() {
  background: $pds-theme-light-background-surface
    linear-gradient(to right, transparent 0%, #f7f7f7 20%, transparent 50%) 0 0 /
    200% 100%;
  animation: skeletonAnimation 0.6s ease infinite;
  min-height: 1rem;
  &::after {
    content: '';
  }

  @keyframes skeletonAnimation {
    from {
      background-position-x: 100%;
    }
    to {
      background-position-x: -100%;
    }
  }
}

.skeletonBox {
  @include pskeleton();
}
