@use '../../shared' as *;

// Prevent flickering
.hidden {
  opacity: 0;
}

.slideInRight {
  animation: slideInRight 0.2s ease;
}

.slideInLeft {
  animation: slideInLeft 0.2s ease;
}

.slideOutRight {
  animation: slideInRight 0.2s ease reverse;
  // Prevent flickering
  opacity: 0;
}

.slideOutLeft {
  animation: slideInLeft 0.2s ease reverse;
  // Prevent flickering
  opacity: 0;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
