@use '../../../manage/overviewFlyout/overview/tariffOverview.module' as overview;
@use '@porsche-design-system/components-js/styles' as *;

.root,
.narContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.root {
  padding-top: overview.$padding;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
  }
}

.narItem {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.narItemContentContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $pds-spacing-static-small;
}

.subContent {
  margin-bottom: $pds-spacing-static-small;
}

.narItemContentWithTitle {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.narItemContentWithoutTitle {
  flex: 1;
  white-space: nowrap;
}

.narStarContainer {
  position: relative;
}

.narStart {
  position: absolute;
  top: 0;
  right: -6px;
}

.taxesDisclaimer {
  margin-top: $pds-spacing-static-small;
  display: flex;
  flex-direction: column;
}
