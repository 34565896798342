@use '../../shared' as *;

.background {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  background-position: 35% 0;
  transition: background-image 0.4s linear;
  min-height: fit-content;
}

.backgroundRedesign {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  background-position: 35% 0;
  transition: background-image 0.4s linear;
  min-height: fit-content;

  @media (max-width: $breakpoint-s-760) {
    background-color: white;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    padding: 30px;
  }

  @media (min-width: $breakpoint-s-760) {
    padding: 82px;
  }

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    min-height: 80vh;
  }

  @media (min-width: $breakpoint-s-760) {
    min-height: calc(100vh - 80px - 80px);
  }
}

.layoutRedesign {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    padding: 0;
    width: 100%;
  }

  @media (min-width: $breakpoint-s-760) {
    padding: 82px;
  }

  @media (min-width: $breakpoint-s-760) {
    min-height: calc(100vh - 80px - 80px);
  }
}

.spacing {
  flex-grow: 100;
}

.panel {
  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    padding: 30px;
  }

  @media (min-width: $breakpoint-s-760) {
    padding: 40px;
    max-width: 720px;
  }

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.75);

  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  animation: appearFromTop 0.4s ease;
}

@keyframes appearFromTop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
