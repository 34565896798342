@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../components/shared' as *;

.mainWrapper {
  background-color: $pds-theme-light-background-surface;
  padding: $pds-spacing-static-large;
  padding-bottom: 0;
  border-radius: $pds-border-radius-medium;
}

.filterWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: $pds-spacing-static-large;

  @media (max-width: $breakpoint-s-760) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.firstDateInput {
  margin-right: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-s-760) {
    margin-right: 0;
    width: 100%;
    margin-bottom: $pds-spacing-static-medium;
  }
}

.secondDateInput {
  @media (max-width: $breakpoint-s-760) {
    width: 100%;
    margin-bottom: $pds-spacing-static-medium;
  }
}

.externalLink {
  margin-left: auto;

  @media (max-width: $breakpoint-s-760) {
    margin-left: 0;
  }
}

.alternateContent {
  padding: $pds-spacing-static-large 0 $pds-spacing-static-large 0;
  display: flex;
  align-items: center;
}
