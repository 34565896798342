* {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.payment-widget {
  background-color: transparent !important;
}
