.priceContainer {
  position: relative;
  width: fit-content;
  display: inline;
}

.star {
  position: absolute;
  top: 0;
  right: -6px;
}
