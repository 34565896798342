@use '../../shared' as *;

.simpleSegmentRoot {
  // Only used by overrides
}

.simpleSegmentTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: $breakpoint-xs-480) {
    align-items: flex-start;
  }
}

.simpleSegmentSubTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: $breakpoint-xs-480) {
    align-items: flex-start;
  }
}
