@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.root {
  background-color: $pds-theme-light-background-surface;
  padding: $pds-spacing-static-large $pds-spacing-static-medium
    $pds-spacing-static-xx-large;
  display: flex;
  justify-content: center;
  gap: $pds-spacing-static-large;
}

.backLink {
  width: fit-content;
}

.title {
  margin: $pds-spacing-static-medium 0;
}

.pushed {
  margin-top: $pds-spacing-static-large;
}

.deliveryAddressText {
  margin-top: $pds-spacing-static-large;
  padding-left: 41px;
}

.checkoutSteps {
  display: flex;
  flex-direction: column;
  gap: $pds-spacing-static-medium;
  max-width: 972px;
  width: 100%;
}

.orderSummaryMobile {
  width: 100%;

  @media screen and (min-width: $breakpoint-m-1000) {
    display: none;
  }
}

.orderSummaryDesktop {
  max-width: 524px;
  width: 100%;
  margin-top: $pds-spacing-static-x-large;

  @media screen and (max-width: $breakpoint-m-1000) {
    display: none;
  }
}
