@use '@porsche-design-system/components-js/styles' as *;

.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  overflow-y: auto;
}

.footer {
  margin-top: auto;
}

.crest {
  align-self: center;
  margin-top: $pds-spacing-static-large;
  margin-bottom: $pds-spacing-static-medium;
}
