@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.sectionTitle {
  margin-bottom: $pds-spacing-static-small;
}

.contentMainBox {
  min-height: 88px;
  width: 100%;
  background-color: $pds-theme-light-background-surface;
  padding: $pds-spacing-static-medium;
  border-radius: $pds-border-radius-large;
  margin-bottom: $pds-spacing-static-medium;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.contentPreviewWrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.contentPreviewTextWrapper {
  display: flex;
  flex-direction: column;
}
