@use '../../../../components/shared' as *;

.loading {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.firstSelected {
  @extend .root;

  > div:nth-child(1) {
    border: 2px solid var(--light-primary-light, #010205);
    padding: 16px;
  }

  > div:nth-child(2) {
    border: 1px solid var(--light-neutral-color-contrast-medium-light, #6b6d70);
    padding: 17px;
  }
}

.secondSelected {
  @extend .root;

  > div:nth-child(1) {
    border: 1px solid var(--light-neutral-color-contrast-medium-light, #6b6d70);
    padding: 17px;
  }

  > div:nth-child(2) {
    border: 2px solid var(--light-primary-light, #010205);
    padding: 16px;
  }
}

.root {
  display: flex;
  gap: 32px;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-s-760) {
    flex-direction: row;
  }

  > div {
    border-radius: 8px;
    cursor: pointer;

    @media (min-width: $breakpoint-s-760) {
      width: calc(50% - 16px);
    }

    > div {
      height: 100%;
    }
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.disabled {
  opacity: 60%;
  pointer-events: none;
  cursor: not-allowed;
}
