@use '@porsche-design-system/components-js/styles' as *;
@use '../../../components/shared' as *;

.root {
  background-color: $pds-theme-dark-background-base;
  color: #fff;
  margin-top: auto;
  padding: $pds-spacing-static-large $pds-spacing-static-x-large;

  @media (max-width: 768px) {
    padding: $pds-spacing-static-large;
  }
}

.normalText {
  color: white;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-right: $pds-spacing-static-small;
}

.longText {
  color: white;
  margin-bottom: $pds-spacing-static-medium;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
}

.elementsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $pds-spacing-static-medium;

  a {
    margin-right: $pds-spacing-static-small;
  }
}

.anchor {
  color: white;
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    background-color: $pds-theme-dark-state-hover;
  }
}

.copyright {
  margin-bottom: $pds-spacing-static-medium;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: $pds-spacing-static-medium;
}

.listItem {
  margin: 0;
  padding: 0;
}
