@use '@porsche-design-system/components-js/styles' as *;

.mainContentWrapper {
  display: grid;
  grid-column: $pds-grid-basic-column-start / $pds-grid-basic-column-end;
}

.gridgrid {
  @include pds-grid;
  margin-bottom: $pds-spacing-static-xx-large;
}

.backButton {
  margin-bottom: $pds-spacing-static-large;
  margin-top: $pds-spacing-static-xx-large;
  width: fit-content;
}

.pageTitle {
  margin-bottom: $pds-spacing-static-medium;
}

.mainText {
  margin-bottom: $pds-spacing-static-x-large;
}

.suspendedNotification {
  margin-top: -$pds-spacing-static-large;
  margin-bottom: $pds-spacing-static-large;
}

.tabItem {
  &::before {
    display: none !important;
  }
}
