@use '@porsche-design-system/components-js/styles' as *;

.row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.manageButton {
  align-self: flex-start;
}

.disclaimer {
  max-width: 300px;
}

.contentLeftWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.title {
  margin-right: $pds-spacing-static-small;
}

.suspendedTag {
  margin-right: auto;
}
