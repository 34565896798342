@use '@porsche-design-system/components-js/styles' as *;
@use '../../../shared.scss' as *;

$padding: 28px;
$halfPadding: calc(#{$padding} / 2);
$percentilePadding: calc(#{$padding} * 0.75);

.floutContentWrapper {
  max-width: 700px;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table {
  width: 100%;
  border-radius: 12px;
  padding: $padding;
  background: var(--light-background-surface-light, #eeeff2);
  gap: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  overflow-x: auto;
  margin-bottom: $pds-spacing-static-large;
  margin-top: $pds-spacing-static-large;
}

.title,
.tableTitle {
  margin-bottom: $pds-spacing-static-medium;
}

.subtitle {
  max-width: 700px;
}

.headerContent {
  margin-bottom: $pds-spacing-static-large;
}

.tableContentContainer {
  display: flex;
  flex-direction: column;
}

.tableContentItem {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: $pds-spacing-static-medium 0;
  border-bottom: 1px solid $pds-theme-dark-background-surface;
}

.tableContentItemLeftSide {
  display: flex;
  flex-direction: column;
}

.tableContentItemRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.body {
  display: grid;
  grid-auto-rows: 1fr;
  width: 100%;

  @media (max-width: $breakpoint-s-760) {
    grid-auto-rows: unset;
  }

  > div {
    @extend .space-between;
    border-bottom: 1px solid rgb(216, 216, 219);
    padding-top: $percentilePadding;
    padding-bottom: $percentilePadding;
    min-height: 48px;
    width: 100%;

    @media (max-width: $breakpoint-s-760) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-top: $pds-spacing-static-small;
      padding-bottom: $pds-spacing-static-small;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        justify-content: center;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    > *:first-child {
      margin-right: 8px;
    }
  }
}

.mainActionButton {
  margin-right: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-s-760) {
    margin-right: 0;
    margin-bottom: $pds-spacing-static-medium;
  }
}

.errorTitle {
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.actionsWrapper {
  display: flex;
  flex-wrap: nowrap;
  margin: $pds-spacing-static-large 0;

  @media (max-width: $breakpoint-s-760) {
    flex-direction: column;
  }
}
