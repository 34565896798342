@use 'components/shared' as *;

.fullWidth {
  width: 100%;
}

.panel {
  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    width: 100%;
  }
  @media (min-width: $breakpoint-s-760) {
    width: 480px;
  }
}
