@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../../components/shared' as *;

.detailPoint {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: $breakpoint-xs-480) {
    gap: 8px;
    flex-direction: column;
  }
}

.tag {
  background-color: $pds-theme-light-primary;
  color: $pds-theme-light-background-base;
  border: 1px solid $pds-theme-light-background-base;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22.4px;
  width: 22.4px;
  min-height: 22.4px;
  min-width: 22.4px;
  max-height: 22.4px;
  max-width: 22.4px;
  margin-top: 4px;

  @include pds-text-xx-small;
  line-height: 1;
}
