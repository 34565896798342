@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;

.root {
  background-color: white;
  margin-inline: $pds-spacing-static-medium;
  padding-block: $pds-spacing-fluid-large;
  margin-bottom: $pds-spacing-static-xx-large;
  @include pds-media-query-min('s') {
    margin-inline: $pds-spacing-fluid-large;
  }
  @include pds-media-query-min('l') {
    margin-inline: $pds-spacing-fluid-xx-large;
  }
}

.stepper {
  padding-bottom: $pds-spacing-fluid-xx-large;
}

.toCSection {
  display: flex;
  flex-direction: column;
  gap: $pds-spacing-fluid-x-small;
  padding: $pds-spacing-fluid-medium 0;
}
.submitWrapper > div {
  width: 100%;
}
.submitWrapper > div > :first-child:hover {
  ~ .popOver {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 100;
    background: #eeeff2;
    margin-top: 1rem;
    max-width: 360px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    box-shadow: 0px 8px 40px 0px #00000029;
    @include pds-media-query-min('l') {
      top: 100%;
    }
  }
}
.popOver {
  display: none;
}
.fullWidthButton {
  width: 100%;
  @media (min-width: 498px) {
    width: auto;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $pds-theme-light-background-surface;
  width: 100%;
  position: relative;
}

.stepperContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  padding: $pds-spacing-static-medium;
  margin-bottom: $pds-spacing-static-medium;
}

.mainContentWrapper {
  display: flex;
  flex-direction: column;
  padding: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-s-760) {
    padding: $pds-spacing-static-medium 0;
  }
}

.informationContainer {
  display: flex;
  flex-wrap: nowrap;
  padding: $pds-spacing-static-medium;
}

.information {
  display: flex;
  flex-direction: column;
  margin-right: $pds-spacing-static-medium;

  @media (max-width: $breakpoint-s-760) {
    margin-right: 0;
  }
}

.stickyOverviewContainer {
  position: relative;
  min-width: 40%;

  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.stickyContentContainer {
  position: sticky;
  top: 8px;
  left: 0;
  background-color: white;
  border-radius: 8px;
  min-width: 100%;
  max-width: 100%;
  padding: $pds-spacing-static-medium;
}

.mobileStickyContentContainer {
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: white;
  border-radius: 8px;
  min-width: 100%;
  max-width: 100%;
  padding: $pds-spacing-static-medium;

  @media (min-width: $breakpoint-s-760) {
    display: none;
  }
}
