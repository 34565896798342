@use '@porsche-design-system/components-js/styles' as *;
@use 'src/components/shared' as *;
.skeletonHeader {
  display: grid;
  padding-top: $pds-spacing-fluid-medium;
  padding-bottom: $pds-spacing-fluid-medium;
  gap: $pds-spacing-fluid-small;
  > :first-child {
    border-radius: $pds-border-radius-small;
    min-height: 3rem;
    max-width: 80%;
  }
  > :last-child {
    border-radius: $pds-border-radius-small;
    max-width: 70%;
  }
}
