@use '@porsche-design-system/components-js/styles' as *;
@use './src/components/shared' as *;

.text {
  color: $pds-theme-light-contrast-medium;
  text-align: left;

  @media (min-width: $breakpoint-s-760) {
    align-items: right;
  }
}
