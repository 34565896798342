.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.bullet {
  border-radius: 4px;
  background: rgb(107, 109, 112);
  width: 8px;
  height: 8px;
  transition:
    background-color 400ms ease 0s,
    width 400ms ease 0s;
}

.bulletActive {
  @extend .bullet;
  background: rgb(1, 2, 5);
  height: 8px;
  width: 20px !important;
}
