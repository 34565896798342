.center {
  height: 500px;
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cancelButton {
  margin-left: 16px;
}
