@use '@porsche-design-system/components-js/styles' as *;
@use '../../../components/shared' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: #000000;
  border: 3px solid #000;
  color: #ffffff;
  min-width: 18px;
  margin-top: 4px;
}

.button {
  &:not(:first-child) {
    margin-left: 16px;

    @media (max-width: $breakpoint-s-760) {
      margin-left: 0;
    }
  }

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-s-760) {
    margin-top: 16px;
    width: 100%;
  }

  @media (min-width: $breakpoint-s-760) {
    width: max-content;
  }
}

.errorCodeText {
  margin-bottom: $pds-spacing-static-large;
}

.failureFrostedItem {
  margin-bottom: $pds-spacing-static-medium;
}
