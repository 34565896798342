@use '@porsche-design-system/components-js/styles' as *;
@use '../../../../components/shared' as *;

.accordionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $pds-spacing-static-large;
}

.desktopChargingTimeContainer {
  display: flex;

  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.startEndTime {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.costContainer {
  @media (max-width: $breakpoint-s-760) {
    display: none;
  }
}

.previewContentWrapper,
.previewContentWrapperClosed {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: $pds-spacing-static-large;
}

.previewContentWrapperClosed {
  margin-bottom: 0;
}

.topLeftIcon {
  margin-right: $pds-spacing-static-large;
}

.previewMainContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.verticalTextDivider {
  margin-left: $pds-spacing-static-small;
  margin-right: $pds-spacing-static-small;
  height: 24px;
}

.previewDateAndTimeWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: $pds-spacing-static-medium;
}

.previewRightContent {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;

  @media (max-width: $breakpoint-s-760) {
    align-items: flex-start;
  }
}

.mobilePriceAndDurationContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-top: $pds-spacing-static-medium;

  @media (min-width: $breakpoint-s-760) {
    display: none;
  }
}

.mobileDurationContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-right: auto;
}

.mobileCostContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.accordionTopRightLabel {
  margin-right: $pds-spacing-static-x-small;
}

.accordionTopRightSecondValue {
  margin-right: $pds-spacing-static-medium;
}

.accordionInnerContentWrapper {
  padding-left: calc(
    $pds-spacing-static-large + $pds-spacing-static-medium +
      $pds-spacing-static-small
  );
  padding-right: $pds-spacing-static-small;
  display: flex;
  flex-direction: column;
  gap: $pds-spacing-static-large;
}

.accordionInnerContentDetails {
  display: flex;
}

.iconWithTextContainer {
  display: flex;
  flex-wrap: nowrap;

  &:first-child {
    margin-right: $pds-spacing-static-large;
  }
}

.iconInnerContent {
  margin-right: $pds-spacing-static-small;
}

.labeledPrice {
  display: flex;
  flex-direction: column;
}

.innerLabeledText {
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
}

.innerContentRightLabel {
  margin-right: $pds-spacing-static-small;
}

.contentWrapper {
  padding-bottom: $pds-spacing-static-large;
}
