@use '../../shared' as *;
.item {
  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-xs-480) {
    margin: 0 0 16px 0;
  }

  @media (min-width: $breakpoint-xs-480) {
    margin: 0 0 36px 0;
  }
}

.header {
  @extend .item;

  @media (min-width: $breakpoint-base-0) and (max-width: $breakpoint-xs-480) {
    font-size: 30.5px;
  }

  @media (min-width: $breakpoint-xs-480) and (max-width: $breakpoint-s-760) {
    font-size: 40px;
  }

  @media (min-width: $breakpoint-s-760) {
    font-size: 50px;
  }
}

.text {
  font-size: 21px;
}
